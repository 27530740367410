import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AppSessionService } from './session/app-session.service';
import { LayoutStoreService } from './layout/layout-store.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from './pipes/localize.pipe';
import { FileSizePipe } from './pipes/fileSize.pipe';
import { MinutesToTimePipe } from './pipes/minutesToTime.pipe';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { AsyncValidators } from './helpers/AsyncValidators';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';

import { ObjectBasicItemComponent } from './components/object-basic-item/object-basic-item.component';
import { AddressComponent } from './components/address/address.component';

import { CustomerItemComponent } from './components/customer-item/customer-item.component';
import { ServiceItemComponent } from './components/service-item/service-item.component';
import { FileEntityListComponent } from './components/file-entity-list/file-entity-list.component';
import { UserItemComponent } from './components/user-item/user-item.component';
import { UserAutocompleteComponent } from './components/user-autocomplete/user-autocomplete.component';

import { ScheduledNotificationComponent } from './components/scheduled-notifications/scheduled-notification/scheduled-notification.component';
import { ScheduledNotificationListComponent } from './components/scheduled-notifications/scheduled-notification-list/scheduled-notification-list.component';
import { UserDeleteItemComponent } from './components/user-delete-item/user-delete-item.component';
import { ContractItemComponent } from './components/contract-item/contract-item.component';
import { CustomerBasicItemComponent } from './components/customer-basic-item/customer-basic-item.component';
import { ContractBasicItemComponent } from './components/contract-basic-item/contract-basic-item.component';
import { WorkContractTypeComponent } from './components/work-contract-type/work-contract-type.component';

import { FileDownloadHelper } from '@shared/helpers/FileDownloadHelper';

import { ServiceActionsItemComponent } from './components/service-actions-item/service-actions-item.component';
import { ContractServiceListComponent } from '@app/contracts/contract-service-list/contract-service-list.component';
import { OrganizationUnitBasicItemComponent } from './components/organization-unit-basic-item/organization-unit-basic-item.component';
import { OrganizationUnitItemComponent } from '@app/organization-units/organization-unit-item/organization-unit-item.component';
import { HrrequestApprovationFlowListComponent } from '@app/hrrequests/hrrequest-approvation-flow-list/hrrequest-approvation-flow-list.component';
import { HrrequestApprovationFlowItemComponent } from '@app/hrrequests/hrrequest-approvation-flow-item/hrrequest-approvation-flow-item.component';
import { HrrequestDetailSupplyingObjectListComponent } from '@shared/components/hrrequest-detail-supplying-object-list/hrrequest-detail-supplying-object-list.component';
import { HrrequestDetailSupplyingObjectItemComponent } from '@shared/components/hrrequest-detail-supplying-object-item/hrrequest-detail-supplying-object-item.component';
import { CurrencySymbolPipe } from './components/hrrequest-detail-supplying-object-item/currency-symbol.pipe';
import { UploadButtonComponent } from '@shared/components/upload-button/upload-button.component';
import { FormErrorsPipe } from './pipes/form-errors.pipe';
import { ProgressTrackingComponent } from '@shared/components/progress-tracking-component/progress-tracking-component';
import { ProgressbarModule } from '@node_modules/ngx-bootstrap/progressbar';
import { SchoolYearServiceProxy } from './service-proxies/service-proxies';
import { MultiCalendarComponent } from './components/multi-calendar/multi-calendar.component';
import { DxSchedulerModule } from '@node_modules/devextreme-angular';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        FormsModule,
        ReactiveFormsModule,
        TypeaheadModule,
        TooltipModule,
        AccordionModule,
        BsDatepickerModule,
        BsDropdownModule,
        NgSelectModule,
        ProgressbarModule,
        DxSchedulerModule,
    ],
    declarations: [
        UploadButtonComponent,
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        FileSizePipe,
        MinutesToTimePipe,
        BusyDirective,
        EqualValidator,
        AddressComponent,
        CustomerItemComponent,
        ServiceItemComponent,
        FileEntityListComponent,
        UserAutocompleteComponent,
        UserItemComponent,
        UserDeleteItemComponent,
        ScheduledNotificationComponent,
        ScheduledNotificationListComponent,
        ContractItemComponent,
        CustomerBasicItemComponent,
        ContractBasicItemComponent,
        WorkContractTypeComponent,
        ObjectBasicItemComponent,
        ServiceActionsItemComponent,
        ServiceItemComponent,
        ObjectBasicItemComponent,
        ContractServiceListComponent,
        OrganizationUnitBasicItemComponent,
        OrganizationUnitItemComponent,
        HrrequestApprovationFlowListComponent,
        HrrequestApprovationFlowItemComponent,
        HrrequestDetailSupplyingObjectListComponent,
        HrrequestDetailSupplyingObjectItemComponent,
        CurrencySymbolPipe,
        FormErrorsPipe,
        ProgressTrackingComponent,
        MultiCalendarComponent,
    ],
    exports: [
        // Abp components
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        // Pipes & directives
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        // @angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // @ngx-bootstrap
        BsDatepickerModule,
        AccordionModule,
        TypeaheadModule,
        TooltipModule,
        BsDatepickerModule,
        BsDropdownModule,
        // other plugins
        NgxPaginationModule,
        NgSelectModule,
        // Components
        AddressComponent,
        CustomerItemComponent,
        ServiceItemComponent,
        FileEntityListComponent,
        UserItemComponent,
        UserDeleteItemComponent,
        UserAutocompleteComponent,
        ScheduledNotificationListComponent,
        ContractItemComponent,
        CustomerBasicItemComponent,
        ContractBasicItemComponent,
        WorkContractTypeComponent,
        ObjectBasicItemComponent,
        ServiceActionsItemComponent,
        ServiceItemComponent,
        ContractServiceListComponent,
        UserItemComponent,
        OrganizationUnitBasicItemComponent,
        OrganizationUnitItemComponent,
        HrrequestApprovationFlowListComponent,
        HrrequestApprovationFlowItemComponent,
        FileSizePipe,
        FormErrorsPipe,
        MinutesToTimePipe,
        HrrequestDetailSupplyingObjectListComponent,
        HrrequestDetailSupplyingObjectItemComponent,
        CurrencySymbolPipe,
        UploadButtonComponent,
        ProgressTrackingComponent,
        MultiCalendarComponent,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [AppSessionService, FormErrorsPipe, AppUrlService, AppAuthService, AppRouteGuard, LayoutStoreService, SchoolYearServiceProxy, FileDownloadHelper, AsyncValidators],
        };
    }
}
