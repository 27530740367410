export enum FeatureConsts {
    Customers = 'Customers',
    Contracts = 'Contracts',
    Services = 'Services',
    HRRequests = 'HRRequests',
    Topics = 'Topics',
    Commitments = 'Commitments',
    Receipts = 'Receipts',
    DueRegister = 'DueRegister',
    Activities = 'Activities',
    ScheduledNotifications = 'ScheduledNotifications',
    Files = 'Files',
    ServiceOperatorsScheduled = 'ServiceOperatorsScheduled',
    OrganizationUnits = 'OrganizationUnits',
    ClassroomActivities = 'ClassroomActivities',
    PresenceChecks = 'PresenceChecks',

    Ascur = 'AscurPlannings',
    Clockings = 'Clockings',
    Plannings = 'Plannings',

    ServiceOperatorsScheduledEnableBeneficiary = 'ServiceOperatorScheduled.EnableBeneficiaries', // Feature che abilita la visualizzazione dei beneficiari nella modifica di una SOS (admin-side)
    ServiceOperatorsScheduledEnableScheduleCalendar = 'ServiceOperatorsScheduled.EnableScheduleCalendar', // Feature che abilita la visualizzazione a calendario delle service operator scheduled
    ServiceOperatorsScheduledEnableScheduleList = 'ServiceOperatorsScheduled.EnableScheduleList', // Feature che abilita la visualizzazione a lista delle service operator scheduled
    ServiceOperatorsScheduledManageScheduleHour = 'ServiceOperatorsScheduled.ManageScheduleHour', // Feature che abilita la gestione delle ore sulle service operator scheduled
    ServiceOperatorsScheduledEnableMultipleInsert = 'ServiceOperatorsScheduled.EnableMultipleInsert', // Feature che abilita l'inserimento multiplo delle service operator scheduled

    ServiceOperatorsScheduledBadgePunchIn = 'ServiceOperatorsScheduled.BadgePunchIn',

    ServiceReports = 'ServiceReports',

    UsersWorkerAdditional = 'Users.WorkerAdditional',
    UsersWorkContractLevel = 'Users.WorkContractLevel',

    EducationalDegreeFiltering = 'EducationalDegreeFiltering',
    CertificationFiltring = 'CertificationFiltring',
    GenderFiltering = 'GenderFiltering',
    ContractFiltering = 'ContractFiltering',
    QualificationFiltering = 'QualificationFiltering',

    UserPaycheckManagement = 'UserPaycheckManagement', // Feature per l'elenco dei pagamenti

    ServiceHasBeneficiary = 'ServiceHasBeneficiary', // Feature per l'elenco dei servizi in quanto beneficiario
}
